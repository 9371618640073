"use client";

import { useEffect, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import {
  Button,
  Dropdown,
  Link,
  Navbar,
  TextInput,
  ThemeMode,
} from "@spheron/ui-library";
import SpheronLogoBlack from "@spheron/ui-library/dist/assets/spheron-specific/spheron-logo-dark.svg";
import SpheronLogoWhite from "@spheron/ui-library/dist/assets/spheron-specific/spheron-logo-2.svg";
import Wallet from "@spheron/ui-library/dist/assets/wallet-2.svg";
import UserIcon from "@spheron/ui-library/dist/assets/user.svg";
import UsersIcon from "@spheron/ui-library/dist/assets/users.svg";
import BlogsIcon from "@spheron/ui-library/dist/assets/document-2.svg";
import PenIcon from "@spheron/ui-library/dist/assets/pen.svg";
import Disconnect from "@spheron/ui-library/dist/assets/logout.svg";
import SearchIcon from "@spheron/ui-library/dist/assets/search.svg";
import { SAMPLE_PROVIDER_ADDRESS } from "@/config";
import { truncateAddress } from "@/utils";
import { useTheme } from "@/utils/ThemeContext";

const NavbarComponent = () => {
  const router = useRouter();
  const pathname = usePathname();
  const [logoIcon, setLogoIcon] = useState<JSX.Element>(<></>);
  const { theme } = useTheme();

  useEffect(() => {
    if (theme) {
      if (theme === ThemeMode.LIGHT) {
        setLogoIcon(
          <button onClick={() => router.push("/")}>
            <SpheronLogoBlack className="w-[116px] cursor-pointer" />
          </button>
        );
      } else {
        setLogoIcon(
          <button onClick={() => router.push("/")}>
            <SpheronLogoWhite className="w-[116px] cursor-pointer" />
          </button>
        );
      }
    }
  }, [theme, router]);

  const navItems = [
    {
      id: "docs",
      text: "Docs",
      handleClick: () => {
        window.open("https://docs.spheron.network/");
      },
    },
    {
      id: 'community',
      text: 'Community',
      handleClick: () => {
        window.open('https://community.spheron.network/');
      },
    },
  ];

  return (
    <Navbar
      logoIcon={logoIcon}
      infoElements={
        <div className="hidden lg:flex flex-row gap-x-5">
          <>
            {navItems.map((item) => {
              return (
                <Link
                  key={item.id}
                  onClick={item.handleClick}
                  size="default"
                  text={item.text}
                  type="secondary"
                  isBold={false}
                />
              );
            })}
          </>
        </div>
      }
    />
  );
};

export default NavbarComponent;
