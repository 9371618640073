"use client";

import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import NavbarComponent from "@/components/navbar";
import Footer from "@/components/footer";
import { setGlobalTheme } from "@/utils";
import OnlyClient from "@/utils/OnlyClient";
import { ThemeProvider, useTheme } from "@/utils/ThemeContext";

const ThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  const { theme } = useTheme();

  useEffect(() => {
    setGlobalTheme(theme);
  }, [theme]);

  return <>{children}</>;
};

export default function Template({ children }: { children: React.ReactNode }) {
  return (
    <>
      <ThemeProvider>
        <ThemeWrapper>
          <ToastContainer newestOnTop limit={2} />
          <nav className="sticky top-0 z-[99]">
            <NavbarComponent />
          </nav>
          <main className="lg:px-14 px-4 min-h-screen max-w-[1400px] mx-auto">
            {children}
          </main>
          <footer className="mt-[100px]">
            <OnlyClient>
              <Footer />
            </OnlyClient>
          </footer>
        </ThemeWrapper>
      </ThemeProvider>
    </>
  );
}
